<template>
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 bg-canvas">
        <HeaderMain></HeaderMain>

        <!-- subnavigation -->
        <div class="container-fluid border-bottom bg-white shadow-sm position-relative py-2 px-4 d-flex align-items-center">
            <span>
                <ButtonCircle class="btn-primary" icon="navigate_before" :onClick="$router.back"></ButtonCircle>

                <nav class="d-inline-block ms-3" style="--bs-breadcrumb-divider: '›';">
                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                        <li class="breadcrumb-item">
                          <a class="text-decoration-none" href="#"
                            @click.prevent="$root.goto( $root.links.APPS )"
                          >Apps</a>
                        </li>
                        <li class="breadcrumb-item">
                          <a class="text-decoration-none" href="#"
                            @click.prevent="$root.goto( $root.links.APPS +'/run/'+  current?.app?.name )"
                          >{{ current?.app?.title }}</a>
                        </li>
                        <li class="breadcrumb-item">
                          {{ current.workbook?.title }}
                        </li>
                        <li class="breadcrumb-item">{{ current.form?.title }}</li>
                    </ol>
                </nav>
            </span>

            <span class="flex-fill"></span>

            <span>
                <ButtonCircle class="" icon="file_upload"></ButtonCircle>
                <ButtonCircle class="ms-2" icon="library_add"></ButtonCircle>
                <ButtonMain class="ms-2 rounded-pill btn-sm fs-6" icon="add" 
					:class="[!current?.form? 'disabled' : '']"
					:onClick="createEntryIntent">New Entry</ButtonMain>
            </span>
        </div>


        <!-- content -->
        <div class="bg-white flex-fill overflow-hidden">
			<div class="d-flex flex-row h-100">
				<!-- FORMS -->
				<div class="workbook-editor-sidebar min-height border-end">
					<!-- <div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0">Forms</h4>

						<div class="d-inline-block flex-fill text-end">
							<button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase me-3" @click.prevent="importFormIntent">IMPORT</button>
							<button type="submit" class="btn btn-sm py-0 btn-dark text-uppercase" @click.prevent="createForm">ADD FORM</button>
						</div>
					</div> -->


					<div class="px-2 pt-4">
						<ListGroup class="align-to-left" :collection="collection" :active="current?.form" :onClick="getFormEntries" :onOption="formOnOption" :options="options"></ListGroup>
					</div>
				</div>



				<!-- FIELDS -->
				<div class="workbook-editor-content flex-fill min-height overflow-scroll">

					<!-- <div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
						<h4 class="mb-0 pb-0">Fields</h4>

						<div class="d-inline-block flex-fill text-end">
							<button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase me-3" @click="addCustomField">Custom</button>
							<button type="submit" class="btn btn-sm py-0 btn-dark text-uppercase" @click="addFormField">ADD FIELD</button>
						</div>
					</div> -->




					<div class="px-2 pt-2">
						<ul class="form-fileds list-group list-group-flush px-2">
							<li v-for="(entry, index) in entries" :key="index" 
								:id="`list-group-item-${entry.name}`"
								:class="[entry?.name == current?.entry?.name? 'active' : '']"
								class="list-group-item d-flex align-items-center">
								<span class="material-icons-outlined float-start text-muted"
                                    style="width: 36px"
                                   >
									{{entryIcon( entry?.type || 'text')}}
								</span>
								<a href="#" @click.prevent="setCurrentEntry(entry)" class="ms-2 text-decoration-none text-reset fw-500 flex-fill">{{ entry.title }}</a>

								<span class="list-group-item-options" >
									<OptionsDropdown class="align-to-left" :context="entry" :collection="options" :onClick="entryOnOption"></OptionsDropdown>
								</span>
							</li>
						</ul>
					</div>


                    <!-- <pre>{{entries}}</pre> -->


				</div>
			</div>
        </div>
        <FooterMain class="bg-white"></FooterMain>
        <FormPanel ref="formPanel" :data="entry" :fields="fields" :primaryField="primaryField" :update="formPanelUpdate"></FormPanel>
    </div>
</template>

<script>

import FormPanel from "./widgets/FormPanel.vue";

export default {
    name: 'WorkbookRun',

    components: {
        FormPanel
    },

    data() {
        return {
            current: {
				app: null,
				workbook: null,
                form: null,
                entry: null,
                fileds: null
            }, 
			formPanel: null,
			offcanvas: null
        }
    },

	computed: {
		collection() {
			(this.forms?.length > 0) && this.forms.map( (item) => {
				item.icon = 'article';
			})
			return this.forms;
		}, 

		options() {
			let clean = { ...this.$ui.helper.options.GENERIC }; clean.title = 'Clean Data'; clean.disabled = true;
			
			let embed = { ...this.$ui.helper.options.GENERIC }; 
				embed.title = 'Embed Code'; 
				embed.name = 'embed'; 
				embed.icon = 'code'; 
				embed.disabled = false;

			let publicURL = { ...this.$ui.helper.options.GENERIC }; 
				publicURL.title = 'Public URL';
				publicURL.icon = 'link';
				publicURL.name = 'publicURL';


			return [
                this.$ui.helper.options.DETAILS,
                this.$ui.helper.options.DUPLICATE,
                this.$ui.helper.options.DIVIDER,
				publicURL,
				embed,
                this.$ui.helper.options.DIVIDER,
                clean,
                this.$ui.helper.options.DELETE,
            ]
		}, 

		forms() {
			return this.$store.state?.forms || [];
		},

		entries() {
			return this.$store.state?.entries;
		},

        entry() {
            let data = JSON.parse(JSON.stringify(this.current.entry));
            return data;
        },

		primaryField() {
			return this.current?.form?.content?.primaryField || 'title';
		},

        fields() {
			let data;
			if(this.current?.fields) {
				data = this.current?.fields;
			}
            return data;
        }

	},



	watch: {
		// '$route' (to) {
		// 	if(this.$route.params?.workbook && to?.name == "WorkbookDetails") {
		// 		this.setCurrentForm();
		// 	}
		// }
	},


    methods: {

        panel(action) {
			if(!this.offcanvas) {
				let panel = document.getElementById('form-panel');
				this.offcanvas = new window.bootstrap.Offcanvas(panel);
			}

			if(action == 'show') { this.offcanvas.show(); }
			if(action == 'hide') { this.offcanvas.hide(); }
			if(action == 'toggle') { this.offcanvas.toggle(); }

			return this.offcanvas;
        },

        createEntryIntent() {
            this.current.entry = null;
            this.current.fields = this.current.form?.content?.fields;

			// let panel = document.getElementById('form-panel');
			// let offcanvas = new window.bootstrap.Offcanvas(panel);
			// 	offcanvas.show();

			this.panel('show')
        },



        async createEntry(data) {

			let title = data?.title || data?.name;

			if(!title) {
				alert('ERROR: missing title for this entry');
				return;
			}

			let request = {
				post_title: title, 
				post_excerpt: data.excerpt || '', 
				post_content: data.content, 
				meta_input: {
					form: this.current.form.ID,
					// ...{
					// 	form: this.current.form.ID,
					// 	workbook: this.current.workbook?.ID
					// }
				}
			};

			await this.$store.dispatch('createEntry', request);
			await this.getFormEntries(this.current.form);
			this.panel('hide');
			this.$root.toast(`The entry "${request.post_title}" has been created.`);
        },


		
        async updateEntry(data) {

			let title = data.title || data.name;

			let request = {
				ID: data.ID, 
				post_title: title, 
				post_excerpt: data.excerpt || '', 
				post_content: data.content, 
				meta_input: {
					...{
						form: this.current.form.ID,
						workbook: this.current.workbook?.ID
					}
				}
			};
			await this.$store.dispatch('updateEntry', request);
			// await this.setCurrentEntry(data);
			this.panel('hide');
			this.$root.toast(`The entry "${request.post_title}" has been updated.`);

        },

		/**
		 * @param data
		 * @param data.name
		 * @param data.title
		 * @param data.excerpt
		 * @param data.content
		 */
        async deleteEntry(data) {

			this.$root.notify({
				title: 'Confirm',
				type: 'confirm',
				message: 'Are you sure you want to delete this entry (' + data.title + ')?',
				callback: async (okay)=> {
					if(okay) {
						await this.$store.dispatch('deleteEntry', data);
						this.$root.toast(`The form "${data.title}" has been deleted.`);
						await this.getFormEntries(this.current.form);
					}
				}
			});
        },


		

		formPanelUpdate(data) {
            console.log('formPanelUpdate', data);
			if(data?.ID) {
				this.updateEntry(data);
			} else {
				this.createEntry(data);
			}
		},



		showFormPublicURL(data) {
			let path = this.$root.links.PUBLIC_FORMS + '/' + data.name;
			this.$root.notify({
				type: 'confirm',
				message: `Would you like to open the form ${data.title} public URL? <br/><br/>${window.location.origin}${path}`,
				callback: (okay)=>{
					if(okay) {
						this.$router.push( {path: path} )
					}
				}
			});
		},


		entryOnOption(option, entry) {
			console.log('entryOnOption', option, entry);
			let action = option?.name;

			switch (action) {
				case 'details':
					this.editEntryIntent(entry);
					break;
				case 'duplicate':
					
					break;
				case 'delete':
					this.deleteEntry(entry);
					break;


				case 'publicURL':
					this.showFormPublicURL(entry);
					break;
			
				default:
					break;
			}
		},


		formOnOption(option, entry) {
			console.log('formOnOption', option, entry);
			let action = option?.name;

			switch (action) {
				case 'publicURL':
					this.showFormPublicURL(entry);
					break;
			
				default:
					break;
			}
		},
		


        async setCurrentEntry(entry) {
			/**
			 * force formpanel to redraw data
			 */
            this.current.entry = null;
            this.current.fields = null;

            let entryDetails = await this.$store.dispatch('getEntry', entry.name);

            this.current.entry = entryDetails;
            this.current.fields = this.current.form?.content?.fields;

			this.panel('show')
            console.log(this.current.entry);
        },



        async getFormEntries(form) {
            this.current.form = form;

            await this.$store.dispatch('getEntriesByFormId', this.current.form.ID);
			this.setPath();
        },



		setPath(replace = false) {
			let path = this.$root.links.APPS + '/run/' + this.current.app?.name + '/' + this.current.workbook?.name + '/' + this.current.form?.name;

			if(replace) {
				this.$router.replace( { path: path } );
			} else {
				this.$router.push( { path: path } );
			}
		},


        entryIcon(type) {
            type
            return 'article';
        },


		async deeplink() {

			if(this.forms?.length && this.forms?.length > 0 && this.$route.params?.form) {
				this.forms.forEach( (item) => {
					if(item.name == this.$route.params?.form) {
						this.current.form = item;
					}
				});
				this.setPath(false);

			} else if(this.forms?.length && this.forms?.length > 0 && !this.$route.params?.form) {
				this.current.form = this.forms[0];
				this.setPath(true)
			}


			console.log('mounted', this.current.form);

			/** will not run if current.form is null */
			this.current.form && await this.getFormEntries(this.current.form);
			
		},

		/**
		 * Others
		 */
		async refresh(forceRefresh = false) {
			let appName = this.$route.params?.app || null;
			let workbookName = this.$route.params?.workbook || null;
			
			if(appName && workbookName) {
				this.current.app = await this.$store.dispatch('getApp', appName);
				this.current.workbook = await this.$store.dispatch('getWorkbook', workbookName);
				await this.$store.dispatch('getFormsByWorkbookId', { workbookId: this.current.workbook.ID, forceRefresh: forceRefresh } );

				/** will not run if current.form is null */
				// this.current?.form && this.getFormEntries(this.current.form);

			}
		}
    }, 

	async mounted() {
		this.current.form = null;
		this.$store.commit('SET_ENTRIES', null);

		await this.refresh(true);
		this.deeplink()

		this.panel();
		window.workbook = this;
	}
}
</script>

<style lang="css" scoped>

.workbook-editor-sidebar {
	min-width: 320px;
	z-index: 10;
}

</style>