<template lang="">
    <div class="border-0 offcanvas offcanvas-end form-panel h-100 d-flex flex-column shadow" tabindex="-1" id="form-panel">
        <div class="offcanvas-header border-bottom">
            <h5 class="px-4 mb-0">{{ panelTitle }}</h5>
            <button type="button" class="btn-close end-0 pe-5 position-absolute text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>


        <div class="offcanvas-body p-4 px-4.5">

            <form @submit.prevent="">
                <div class="mb-3" 
                    v-for="(field, index) in fields" :key="index">
                    <label for="entry-title" class="form-label">{{ field?.title }}</label>
                    <!-- text -->
                    <input v-if="field?.type == 'text'" v-model="current.entry.content[field?.key]" class="form-control text-input fs-5" id="entry-title" type="text" :name="field?.key">

                    <!-- number -->
                    <input v-if="field?.type == 'number'" v-model="current.entry.content[field?.key]" class="form-control text-input fs-5" id="entry-title" type="number" :name="field?.key">

                    <!-- color -->
                    <div v-if="field?.type == 'color'" class="d-flex flex-row color-field">
                        <input v-model="current.entry.content[field?.key]" class="form-control text-input" :id="'field-color-' + field?.key" type="text" :name="field?.key"
                            @blur="($event)=>updateColor('field-color-picker-' + field?.key, $event.target.value, field?.key)">
                            
                        <input v-model="current['color_' + field?.key]" class="form-control color-input p-1 w-25 ms-3" :id="'field-color-picker-' + field?.key" type="color" 
                            style="height: auto"
                            placeholder="#eeeeee"
                            @blur="($event)=>updateColor('field-color-' + field?.key, $event.target.value, field?.key)">
                    </div>

                    <!-- textarea -->
                    <textarea v-if="field?.type == 'textarea'" v-model="current.entry.content[field?.key]" class="form-control text-input fs-5" id="entry-title" :name="field?.key"></textarea>


                    <input v-if="!['text', 'number', 'color', 'textarea'].includes(field?.type)" v-model="current.entry.content[field?.key]" class="form-control text-input fs-5" id="entry-title" type="text" :name="field?.key">

                    <small class="fs-6 text-muted">{{ field?.hint }}</small>
                    <!-- <small class="float-end">ⓘ</small> -->
                    <p></p>
                </div>  
            </form>

            <!-- <pre>{{ data }}</pre> -->
            <!-- <pre>{{ current.entry }}</pre> -->
            <!-- <pre>{{ entry }}</pre> -->
            <!-- <pre>{{ fields }}</pre> -->
        </div>

        <div class="offcanvas-footer border-top">
            <div class="px-4 py-2 text-end">
                <a class="btn me-3" tabindex
                    v-if="data?.ID"
                    @click.prevent="restore"
                    >RESTORE</a>

                <button class="btn btn-primary btn-focus"
                    @click.prevent="save"
                    >{{actionButtonLabel}}</button>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormPanel',

    props: {
        data: Object, 
        fields: Object,
        update: Function, 
        primaryField: String
    },

    data() {
        return {
            current: {
                entry: {
                    content: {}
                },
            },
            originalEntry: null
        }
    }, 

    computed: {
        panelTitle() {
            let title = this.current?.entry?.content?.[this.primaryField] || 'New entry';
            return title;
        },

        actionButtonLabel() {
            let label = this.data?.ID? 'UPDATE ENTRY' : 'SAVE ENTRY'
            return label;
        },
    }, 


    methods: {

        showTab(selector) {
            console.log('selector: ' + selector)
            var tabEl = this.$el.querySelector(selector);
            var tab = new window.bootstrap.Tab(tabEl);
                tab.show();
        },


        fetch(question) {
            question;
            this.answer = null;
            this.showTab('.tab-answer');
            this.answer = {
                title: question.title,
                content: question.content
            }
        }, 

        focusOn(selector, delay) {
            delay = delay || 100;
            setTimeout(()=>{
                this.$el.querySelector(selector).focus();
            }, delay);
        }, 


        restore() {
            this.current.entry = JSON.parse(JSON.stringify(this.originalEntry));
            setTimeout(this.updateColors, 250);
        },

        save() {
            if(this.update) {
                this.current.entry.title = this.current?.entry?.content?.[this.primaryField];
                this.update(this.current.entry)
            }
        }, 

        updateColors() {
            let $ = window.$;
            $('.color-field').each((i, item)=>{
                let value = $(item).find('.text-input').val();
                $(item).find('.color-input').val(value);
            });
        }, 

        updateColor(id, value, key) {
            this.$el.querySelector('#' + id).value = value;
            this.current.entry.content[key] = value;
        }
    },

    mounted() {
        window.entry = this;
        let panel = this.$el;
        panel.addEventListener('shown.bs.offcanvas', ()=> {
            // this.$store.dispatch('fetchTableOfContents');
            // console.log('show.bs.offcanvas', this.data)

            // this.current.entry = {
            //     content: {}
            // };


            if(this.data?.ID) {
                setTimeout(()=>{
                    this.current.entry = JSON.parse(JSON.stringify(this.data));
                    this.originalEntry = JSON.parse(JSON.stringify(this.data));
                    console.log('shown.bs.offcanvas', this.current.entry)
                }, 100);

                
                setTimeout(()=>{
                    this.updateColors();
                }, 250);
            }
        });


        panel.addEventListener('hidden.bs.offcanvas', ()=> {
            this.current.entry = {
                content: {}
            };
        });

    },

    
}
</script>
<style scope>

.btn-focus:focus {
    box-shadow: 0 0 0 0.25rem rgb(163 200 255 / 50%) !important;
}


.form-panel {
    width: 500px;  
    transition: transform .2s ease-in-out !important;
} 

/* .panel-animation {
    transition: all 0.5s;
    transition-delay: 0s;
    transform: translateY(100px);
}
.panel-animation.active {
    transform: translateY(0);
} */


.text-term {
    padding: 0.75rem 1rem;
}

.hover\:text-undeline:hover {
        text-decoration: underline!important;
}
</style>